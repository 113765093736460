.privacy-root-container {
    height: 92%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
}
.privacy-text-contaner {
    width: 40%;
    margin-top: 20px;
    padding: 30px;
    text-align: center;
    margin-bottom: 50px;
    /* box-shadow: 5px 10px 18px #888888; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.privacy-text-contaner p {
    text-align: left;
}
.privacy-text-contaner li {
    text-align: left;
}

@media screen and (max-width: 1024px) {
    .privacy-text-contaner {
        width: 80%;
    }
}

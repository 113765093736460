.rootContainer {
    display: flex;
    height: 400px;
    width: 400px;
    background-color:rgb(255, 255, 255);
    box-shadow:
   0 12.5px 10px rgba(0, 0, 0, 0.034),
   0 6.7px 5.3px rgba(0, 0, 0, 0.048),
   0 12.5px 10px rgba(0, 0, 0, 0.06),
   0 22.3px 17.9px rgba(0, 0, 0, 0.072),
   0 41.8px 33.4px rgba(0, 0, 0, 0.086),
   0 100px 80px rgba(0, 0, 0, 0.12);
   border-radius: 10px;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   margin: auto;
}
.formStyle {
    display: flex;
     flex-direction: column;
     width: 100%;
}
.inputStyle {
    height: 30px;
    width: 70%;
    margin: auto;
    margin-top: 10px;
    border: none;
    background-color: rgb(235, 235, 235);
    padding: 7px;
}
.buttonStyle {
   font-size: 12px;
    font-style: normal;
    font-weight: bold;
    background-color: rgb(69, 69, 238);
    color: white;
    width: 50%;
    height: 30px;
    border: none;
    align-self: center;
    border-radius: 50px;
    margin-top: 10px;
}
.reset-success-page {
    height: 50%;
    width: 50%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 10px 18px #888888;
    padding: 20px;
}
.reset-verify-result {
    height: 90px;
}
@media screen and (max-width: 1024px) {
    .rootContainer {
        display: flex;
        height: 330px;
        width: 300px;
        background-color:rgb(255, 255, 255);
        box-shadow:
       0 12.5px 10px rgba(0, 0, 0, 0.034),
       0 6.7px 5.3px rgba(0, 0, 0, 0.048),
       0 12.5px 10px rgba(0, 0, 0, 0.06),
       0 22.3px 17.9px rgba(0, 0, 0, 0.072),
       0 41.8px 33.4px rgba(0, 0, 0, 0.086),
       0 100px 80px rgba(0, 0, 0, 0.12);
       border-radius: 10px;
       align-items: center;
       justify-content: center;
       flex-direction: column;
       margin: auto;
     }
     .reset-success-page {
        height: 50%;
        width: 70%;
    }
    .reset-verify-result {
        height: 60px;
    }
}
.header-root-header {
    height: 60px;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(189, 36, 209);
    overflow: hidden;
    z-index: 10;
}
.header-nav-style {
    width: 60%;
    height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.header-ul {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
}
.nav-active {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
}
.header-ul li {
    list-style: none;
    display: flex;
    align-items: center;
    height: 60px;
}
.nav-active li {
    list-style: none;
    display: flex;
    align-items: center;
    height: 60px; 
}
.Link {
    text-decoration: none;
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 45px;
}
.header-ul li:hover {
    background-color: rgb(72, 72, 219);
}
.nav-active li:hover {
    background-color: rgb(72, 72, 219);
}
.header-menu-style {
    display: none;
}

@media screen and (max-width: 1024px) {
    .header-ul {
        position: absolute;
        right: 0px;
        height: 50%;
        top: 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;
        padding: 0px;
    }
    .header-ul li {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(189, 36, 209);
    }
    .header-menu-style {
        display: block;
        font-size: 30px;
        color: white;
        font-style: normal;
        font-weight: normal;
        transform: rotate(90deg);
        padding: 20px;
        cursor: pointer;
    }
    .header-ul li .Link {
        opacity: 0;
    }
    .nav-active {
        position: absolute;
        right: 0px;
        height: 50%;
        top: 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        transform: translateX(0%);
        transition: transform 0.5s ease-in;
        background-color: rgb(189, 36, 209);
        padding: 0px;
    }
    .nav-active li {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(189, 36, 209);
        list-style: none;
    }
    .nav-active li:hover {
        background-color: rgb(72, 72, 219);
    }
    .nav-active li .Link {
        opacity: 1;
    }
    @keyframes navLinkFade {
        from {
            opacity: 0;
            transform: translateX(50px);
        }
        to {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}
.root-container {
    height: 92%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
}
.home-content-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.home-video-style {
    height: 300px;
    width: 530px;
}
.home-platform {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 900px) {
    .home-video-style {
        height: 200px;
        width: 350px;
    }
    .home-platform {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.verify-success-page {
    height: 50%;
    width: 50%;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 5px 10px 18px #888888;
    padding: 20px;
}
.verify-result {
    height: 90px;
}

@media screen and (max-width: 1024px) {
    .verify-success-page {
        height: 50%;
        width: 70%;
    }
    .verify-result {
        height: 60px;
    }
}